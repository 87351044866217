










































































@import '@design';

.treps-page-section {
  color: white;
  background-color: $color-treps-navy;
  .treps-section--title {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    border-top: 13px solid $color-treps-blue;
    border-bottom: 13px solid $color-treps-blue;
  }
}
